
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































/* stylelint-disable selector-class-pattern */
.sustainability-list,
[class*='sustainability-list--'] {
  @extend %text-center;
}

.sustainability-list__icon {
  margin-bottom: var(--spacing-s);
}

.sustainability-list__title {
  margin-bottom: var(--spacing-s);
}

.sustainability-list__list {
  @extend %list-nostyle;

  margin-top: var(--spacing-l);
  text-align: left;
  counter-reset: sustainability;

  @include mq(l) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-m) var(--spacing-l);

    .cpt-sustainabilityListIcons & {
      grid-template-columns: repeat(3, 1fr);
      gap: 0 var(--spacing-l);
    }
  }
}

.sustainability-list__item {
  @include numbered-list-item(sustainability);

  position: relative;
  counter-increment: sustainability;

  & + & {
    margin-top: var(--spacing-m);
  }

  .cpt-sustainabilityListIcons & {
    display: block;
    padding: 2.4rem 0 4rem;
    border-bottom: 0.1rem solid $c-light-grey;

    &::before {
      content: none;
    }
  }

  @include mq(l) {
    & + & {
      margin-top: 0;
    }

    .cpt-sustainabilityListIcons & {
      &:first-child {
        grid-row: 1/3;
        grid-column: 1/2;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        grid-row: 1/3;
        grid-column: 3/4;
      }
    }
  }
}

.sustainability-list__item__title {
  display: flex;
  align-items: center;
  gap: $spacing;

  svg {
    flex-shrink: 0;
  }

  @include mq(l) {
    font-size: 3rem;
  }
}

.sustainability-list__item__text {
  position: relative;

  .cpt-sustainabilityListIcons & {
    margin-top: var(--spacing-s);
  }

  .cpt-sustainabilityList & {
    /* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
    ::v-deep p:first-child {
      margin-bottom: var(--spacing-xs);

      // Follow h3 size
      strong:only-child {
        @extend %ff-alt;

        font-size: 2rem;
        line-height: 1.2;
      }
    }
  }

  @include mq(l) {
    ::v-deep p:first-child strong:only-child {
      font-size: 3rem;
    }
  }
  /* stylelint-enable selector-max-combinators, selector-max-compound-selectors */
}

.sustainability-list__item__link {
  @extend %fw-bold;
  @extend %ff-alt;

  display: flex;
  align-items: center;
  padding-top: var(--spacing-s);

  ::v-deep .action__label {
    font-size: 1.8rem;
  }

  ::v-deep svg {
    color: $c-mustard;
  }
}

.sustainability-list__item__pictures {
  display: grid;
  gap: $spacing * 0.5;
  margin-top: var(--spacing-m);

  img {
    width: 100%;

    &:only-child {
      @extend %border-radius-small;
    }
  }

  @include mq(xxs) {
    grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  }

  @include mq(l) {
    margin-top: var(--spacing-s);
    gap: $spacing;

    .cpt-sustainabilityListIcons .sustainability-list__item:last-child & {
      order: -1;
      margin: 0 0 var(--spacing-m);
    }
  }
}
